import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
// import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Banner from '../components/banner';
import { htmlDecode } from '../Utils';
import Link from '../components/link';

const PostTemplate = ({ data, pageContext }) => {
  const seoTitle = data.strapiPost.seo
    ? data.strapiPost.seo.seoTitle
    : data.strapiPost.title;
  const seoDesc = data.strapiPost.seo ? data.strapiPost.seo.seoDescription : '';
  const termName = data.strapiPost?.categories?.[0]?.name
    ? data.strapiPost.categories[0].name
    : '';
  const termSlug = data.strapiPost?.categories?.[0]?.slug
    ? data.strapiPost.categories[0].slug
    : '';
  const cdnUrl = 'https://dlyog079eaher.cloudfront.net';
  const featuredImageUrl = data.strapiPost?.heroModule?.bannerImage
    ? data.strapiPost.heroModule.bannerImage?.url
    : data.strapiPost?.featuredImage?.url;

  return (
    <Layout
      classNames={`post-template-default single single-post single-format-standard ${data.strapiPost.id}`}
    >
      <Helmet>
        <script
          type="text/javascript"
          src="https://platform-api.sharethis.com/js/sharethis.js#property=62ab5287c11373001913fbdd&product=sop"
          async="async"
        ></script>
        {/*<script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-61f6c50e0b93d646"></script>*/}
      </Helmet>
      <Seo
        title={data.strapiPost.title}
        seoTitle={seoTitle}
        seoDescription={seoDesc}
        featImage={featuredImageUrl}
      />
      {data.strapiPost.heroModule && (
        <Banner
          data={data.strapiPost.heroModule}
          term={termName}
          termSlug={termSlug}
          title={data.strapiPost.title}
          type="post"
          featuredImage={
            featuredImageUrl?.includes(cdnUrl)
              ? featuredImageUrl
              : cdnUrl + featuredImageUrl
          }
        />
      )}
      {!data.strapiPost.heroModule && (
        <section className="banner-wrap">
          <div id="banner" className={`banner ${termSlug}`}>
            <div
              className="banner-bg-image col-12 ph0 small"
              style={{
                backgroundImage: `url("${
                  featuredImageUrl?.includes(cdnUrl)
                    ? featuredImageUrl
                    : cdnUrl + featuredImageUrl
                }")`,
                opacity: 1,
              }}
            />
            <div className="overlay diag-gradient" />
            <div className={`banner-inside ${termSlug} aic jcc`}>
              <div
                className={`col-12 col-sm-10 col-md-7 banner-text ${termSlug}  m-center text-center blue-overlay diag-gradient`}
              >
                <span className="cat-btn mb3 dib bcw tcw">{termName}</span>
                <h1>{htmlDecode(data.strapiPost.title)}</h1>
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="content-wrap">
        <div className="post-type-post">
          <div className="container">
            <div className="row">
              <div className="col-12 blog-content-wrap ph4">
                <div className="row">
                  <div className="col-12 col-md-8 col-xl-7 blog-content-inner">
                    <div className="col-12 author-share-row dif jcsb ph0 aic">
                      {data.strapiPost?.authors?.[0] && (
                        <div className="author">
                          By {data.strapiPost.authors[0].firstName}{' '}
                          {data.strapiPost.authors[0].lastName}
                        </div>
                      )}
                      <div className="social dif aic">
                        {/*<span className="tcg11 mr1">Share</span> <div className="addthis_inline_share_toolbox" />*/}
                        <span className="tcg11 mr1">Share</span>{' '}
                        <div className="sharethis-inline-share-buttons"></div>
                        <button className="st-btn copy-link">
                          <i className="fa-share"></i>
                        </button>
                        <div id="link-copied-text">link copied!</div>
                      </div>
                    </div>
                    {data.strapiPost.content && (
                      <div
                        className="copy"
                        dangerouslySetInnerHTML={{
                          __html: data.strapiPost.content,
                        }}
                      />
                    )}
                  </div>
                  <div className="blog-sidebar col-12 col-md-4 offset-xl-1 ph1 only-xs-ph0">
                    <Link
                      className="button uppercase"
                      href="/subscribe/"
                      style={{ margin: '18px 0 30px' }}
                    >
                      Subscribe to Blog
                    </Link>
                    <ul className="nav nav-tabs" role="tablist">
                      {pageContext.popularPosts.edges.length > 0 && (
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#popular"
                            role="tab"
                          >
                            Popular
                          </a>
                        </li>
                      )}
                      {pageContext.recentPosts.edges.length > 0 && (
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#recent"
                            role="tab"
                          >
                            Recent
                          </a>
                        </li>
                      )}
                    </ul>
                    <div className="tab-content">
                      {pageContext.popularPosts.edges.length > 0 && (
                        <div
                          className="tab-pane fade show active"
                          id="popular"
                          role="tabpanel"
                        >
                          {pageContext.popularPosts.edges.map(
                            (popular, index) => (
                              <div className="list-item col-12 brdr1--bottom bcg3 pv2.5 ph0">
                                {popular.node.featuredImage && (
                                  <div className="col-12 col-lg-6 pl0 only-xs-ph0">
                                    <div className="list-content_image-wrap--sb">
                                      <Link
                                        href={`/blog/${popular.node.slug}`}
                                        title={htmlDecode(popular.node.title)}
                                      >
                                        {popular.node.featuredImage?.formats
                                          ?.medium && (
                                          <img
                                            alt={htmlDecode(popular.node.title)}
                                            className="dbha"
                                            src={`${
                                              popular.node.featuredImage.formats.medium.url.includes(
                                                cdnUrl
                                              )
                                                ? popular.node.featuredImage
                                                    .formats.medium.url
                                                : cdnUrl +
                                                  popular.node.featuredImage
                                                    .formats.medium.url
                                            }`}
                                          />
                                        )}
                                        {!popular.node.featuredImage?.formats
                                          ?.medium && (
                                          <img
                                            alt={htmlDecode(popular.node.title)}
                                            className="dbha"
                                            src={`${
                                              popular.node.featuredImage.url.includes(
                                                cdnUrl
                                              )
                                                ? popular.node.featuredImage.url
                                                : cdnUrl +
                                                  popular.node.featuredImage.url
                                            }`}
                                          />
                                        )}
                                      </Link>
                                    </div>
                                  </div>
                                )}
                                <div className="col-12 col-lg-6 preview pr0 only-xs-ph0">
                                  {popular.node.categories &&
                                    popular.node.categories.length > 0 && (
                                      <span className="cat-btn mb1 dib">
                                        {popular.node.categories[0].name}
                                      </span>
                                    )}
                                  <Link href={`/blog/${popular.node.slug}`}>
                                    <h3 className="teaser-subhead">
                                      {htmlDecode(popular.node.title)}
                                    </h3>
                                  </Link>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                      {pageContext.recentPosts.edges.length > 0 && (
                        <div className="tab-pane" id="recent" role="tabpanel">
                          {pageContext.recentPosts.edges.map(
                            (recent, index) => (
                              <div className="list-item col-12 brdr1--bottom bcg3 pv2.5 ph0">
                                {recent.node.featuredImage && (
                                  <div className="col-12 col-lg-6 pl0 only-xs-ph0">
                                    <div className="list-content_image-wrap--sb">
                                      <Link
                                        href={`/blog/${recent.node.slug}`}
                                        title={htmlDecode(recent.node.title)}
                                      >
                                        {recent.node.featuredImage?.formats
                                          ?.medium && (
                                          <img
                                            alt={htmlDecode(recent.node.title)}
                                            className="dbha"
                                            src={`${
                                              recent.node.featuredImage.formats.medium.url.includes(
                                                cdnUrl
                                              )
                                                ? recent.node.featuredImage
                                                    .formats.medium.url
                                                : cdnUrl +
                                                  recent.node.featuredImage
                                                    .formats.medium.url
                                            }`}
                                          />
                                        )}
                                        {!recent.node.featuredImage?.formats
                                          ?.medium && (
                                          <img
                                            alt={htmlDecode(recent.node.title)}
                                            className="dbha"
                                            src={`${
                                              recent.node.featuredImage.url.includes(
                                                cdnUrl
                                              )
                                                ? recent.node.featuredImage.url
                                                : cdnUrl +
                                                  recent.node.featuredImage.url
                                            }`}
                                          />
                                        )}
                                      </Link>
                                    </div>
                                  </div>
                                )}
                                <div className="col-6 preview pr0 only-xs-ph0">
                                  {recent.node.categories &&
                                    recent.node.categories.length > 0 && (
                                      <span className="cat-btn mb1 dib">
                                        {recent.node.categories[0].name}
                                      </span>
                                    )}
                                  <Link href={`/blog/${recent.node.slug}`}>
                                    <h3 className="teaser-subhead">
                                      {htmlDecode(recent.node.title)}
                                    </h3>
                                  </Link>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 prev-next-post-wrap only-ph0">
                <div className="col-12 prev-next-post dif ph0">
                  <div className="col-12 col-sm-6 ph0">
                    {pageContext.previous && (
                      <>
                        <Link
                          href={`/blog/${pageContext.previous.slug}`}
                          className="pre-next-link link"
                        >
                          <span className="fa fa-angle-left"></span> Previous
                          Post{' '}
                        </Link>
                        <span className="copy">
                          {htmlDecode(pageContext.previous.title)}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 ph0 tar">
                    {pageContext.next && (
                      <>
                        <Link
                          href={`/blog/${pageContext.next.slug}`}
                          className="pre-next-link link"
                        >
                          Next Post <span className="fa fa-angle-right"></span>
                        </Link>
                        <span className="copy">
                          {htmlDecode(pageContext.next.title)}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PostTemplate;

export const query = graphql`
  query PostTemplate($id: String!) {
    strapiPost(id: { eq: $id }) {
      id
      seo {
        excludeFromSitemap
        noFollow
        noIndex
        seoTitle
        seoDescription
      }
      title
      content
      featuredImage {
        alternativeText
        url
      }
      heroModule {
        bannerHaveMarquee
        bannerImage {
          alternativeText
          url
          formats {
            large {
              url
            }
          }
        }
        bannerImageOrVideo
        bannerIncorporateVideo
        bannerOverlay
        bannerSize
        bannerTitle
        fullWidthTextBlock
        textBlockContentAlignment
        textBlockHorizontalAlignment
        textBlockVerticalAlignment
        textColor
      }
      authors {
        firstName
        lastName
      }
      categories {
        name
        slug
      }
      tags {
        name
      }
    }
  }
`;
